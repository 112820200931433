import React from "react"
import LayoutComponent from "../../components/Layout.js"
import Ultralyd from "../../components/Ultralyd.js"
import Knap from "../../components/Knap.js"


const UltralydPage = () => (
    <LayoutComponent>
        <h1>Ultraljud</h1>
        <p>I samråd med våra leverantörer har vi utvidgat vår leveranskompetens till att också inkludera ultraljudstillbehör i form av träningsfantomer, kvalitetskontroll (QA) och mjukdelssimulation.</p>
        <h1>Våra leverantörer</h1>
        <Ultralyd />
        <Knap />
    </LayoutComponent>
)
export default UltralydPage